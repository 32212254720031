<template>
  <svg viewBox="0 0 20 20">
    <path
      d="M10.0000198,0 C7.28416306,0 6.94361912,0.0115129392 5.87701279,0.0601848819
      C4.81262938,0.108737725 4.0856937,0.277819132 3.44962002,0.525069425 C2.79203161,0.780616974
      2.23435568,1.12259097 1.67838663,1.67858653 C1.12245728,2.23462179 0.780524015,2.79236414
      0.525006897,3.45003087 C0.277786048,4.0861803 0.108724776,4.81320256 0.0601777148,5.87771274
      C0.0115115681,6.9444461 0,7.2850306 0,10.0012108 C0,12.7173514 0.0115115681,13.0579359
      0.0601777148,14.1246693 C0.108724776,15.1891794 0.277786048,15.9162017 0.525006897,16.5523511
      C0.780524015,17.2100178 1.12245728,17.7677602 1.67838663,18.3237955 C2.23435568,18.879791
      2.79203161,19.221765 3.44962002,19.4773523 C4.0856937,19.7245629 4.81262938,19.8936443
      5.87701279,19.9421971 C6.94361912,19.990869 7.28416306,20.002382 10.0000198,20.002382
      C12.7158369,20.002382 13.0563809,19.990869 14.1229872,19.9421971 C15.1873706,19.8936443
      15.9143063,19.7245629 16.55038,19.4773523 C17.2079684,19.221765 17.7656443,18.879791
      18.3216134,18.3237955 C18.8775427,17.7677602 19.219476,17.2100178 19.4750328,16.5523511
      C19.722214,15.9162017 19.8912752,15.1891794 19.9398223,14.1246693 C19.9884884,13.0579359
      20,12.7173514 20,10.0012108 C20,7.2850306 19.9884884,6.9444461 19.9398223,5.87771274
      C19.8912752,4.81320256 19.722214,4.0861803 19.4750328,3.45003087 C19.219476,2.79236414
      18.8775427,2.23462179 18.3216134,1.67858653 C17.7656443,1.12259097 17.2079684,0.780616974
      16.55038,0.525069425 C15.9143063,0.277819132 15.1873706,0.108737725 14.1229872,0.0601848819
      C13.0563809,0.0115129392 12.7158369,0 10.0000198,0 Z M10.0000198,1.80201318
      C12.6701082,1.80201318 12.9863985,1.81221602 14.0408581,1.86033217 C15.0158483,1.90479593
      15.5453407,2.06772387 15.8977138,2.20468815 C16.364488,2.38611619 16.697609,2.60283734
      17.0475209,2.95283069 C17.3974726,3.30278434 17.614168,3.63594498 17.7955744,4.10277482
      C17.9325224,4.45518985 18.0954309,4.98474536 18.1398894,5.9598516 C18.1879998,7.01443683
      18.1982014,7.33076476 18.1982014,10.0012108 C18.1982014,12.6716172 18.1879998,12.9879452
      18.1398894,14.0425304 C18.0954309,15.0176366 17.9325224,15.5471921 17.7955744,15.8996072
      C17.614168,16.366437 17.3974726,16.6995976 17.0475209,17.0495513 C16.697609,17.3995446
      16.364488,17.6162658 15.8977138,17.7976938 C15.5453407,17.9346581 15.0158483,18.0975861
      14.0408581,18.1420498 C12.9865573,18.190166 12.6703067,18.2003688 10.0000198,18.2003688
      C7.3296933,18.2003688 7.01348243,18.190166 5.95914187,18.1420498 C4.98415175,18.0975861
      4.45465931,17.9346581 4.10228624,17.7976938 C3.635512,17.6162658 3.30239103,17.3995446
      2.95247906,17.0495513 C2.60256708,16.6995976 2.38583204,16.366437 2.2044256,15.8996072
      C2.06747764,15.5471921 1.9045691,15.0176366 1.86011063,14.0425304 C1.81200021,12.9879452
      1.80179858,12.6716172 1.80179858,10.0012108 C1.80179858,7.33076476 1.81200021,7.01443683
      1.86011063,5.9598516 C1.9045691,4.98474536 2.06747764,4.45518985 2.2044256,4.10277482
      C2.38583204,3.63594498 2.60252738,3.30278434 2.95247906,2.95283069 C3.30239103,2.60283734
      3.635512,2.38611619 4.10228624,2.20468815 C4.45465931,2.06772387 4.98415175,1.90479593
      5.95914187,1.86033217 C7.01360151,1.81221602 7.32989177,1.80201318 10.0000198,1.80201318
      L10.0000198,1.80201318 Z">
    </path>
    <path
      d="M10.0000198,13.3349213 C8.15904224,13.3349213 6.66666667,11.842368 6.66666667,10.0012108
      C6.66666667,8.16001397 8.15904224,6.66746066 10.0000198,6.66746066 C11.8409578,6.66746066
      13.3333333,8.16001397 13.3333333,10.0012108 C13.3333333,11.842368 11.8409578,13.3349213
      10.0000198,13.3349213 Z M10.0000198,4.86544749 C7.16392671,4.86544749 4.86486808,7.16477993
      4.86486808,10.0012108 C4.86486808,12.8376021 7.16392671,15.1369345 10.0000198,15.1369345
      C12.8360733,15.1369345 15.1351319,12.8376021 15.1351319,10.0012108 C15.1351319,7.16477993
      12.8360733,4.86544749 10.0000198,4.86544749 L10.0000198,4.86544749 Z">
    </path>
    <path
      d="M16.5380348,4.66254186 C16.5380348,5.32536956 16.0007621,5.86266652 15.3380134,5.86266652
      C14.6753043,5.86266652 14.1380316,5.32536956 14.1380316,4.66254186 C14.1380316,3.99971416
      14.6753043,3.4623775 15.3380134,3.4623775 C16.0007621,3.4623775 16.5380348,3.99971416
      16.5380348,4.66254186">
    </path>
  </svg>
</template>
